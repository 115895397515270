<template>
  <LayoutWrapper>
    <router-view />
  </LayoutWrapper>
</template>
<script lang="ts">
import LayoutWrapper from "@/layouts/LayoutWrapper.vue"
export default {
  components: {
    LayoutWrapper,
  },
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

h1, h2, h3, h4, h5, h6, * {
  font-family: 'IBM Plex Mono', monospace;
  color: white;
}
</style>
