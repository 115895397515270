<template>
  <component :is="layoutComponent">
    <slot/>
  </component>
</template>

<script lang="ts">
import {computed} from "vue"
import {useRoute} from "vue-router"
import BasicLayout from "@/layouts/BasicLayout.vue"
import HomeLayout from "@/layouts/HomeLayout.vue"

export default {
  components: {
    BasicLayout,
    HomeLayout,
  },
  setup() {
    const route = useRoute()
    const layoutComponent = computed(() => {
      if (route.meta.layout === "public") {
        return BasicLayout
      } else if (route.meta.layout === "home") {
        return HomeLayout
      } else {
        return BasicLayout
      }
    })

    return {layoutComponent}
  },
}
</script>
